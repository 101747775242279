import React from "react";
import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";
import Card from "../components/Premium";
import Basic from "../components/BasicPack";
import Master from "../components/MasterCheck-up";
import Grid from "@material-ui/core/Grid";
import Exclusive from "../components/Exclusivecheck-up";

function Healthcheckup() {
  return (
    <div style={{ margin: "14px" }}>
      <Container>
        <Typography variant="h6">
          It is known fact that heart diseases are on the rise in India from
          past 10 years. Coronary artery disease which is previously seen in
          older age groups are now seen even in young people as early as 20
          years of age. So many factors like
        </Typography>
        <Typography paragraph>
          <li>Diet</li>
          <li>family history</li>
          <li>smoking</li>
          <li>alcohol consumption</li>
          <li>unhealthy life style</li>
          <li>contribute to the risk of coronary artery disease.</li>
        </Typography>
        <Typography variant="h6">
          As it is well known that <b>“PREVENTION IS BETTER THAN CURE”</b> we at
          <b>“UV cardiac care hospital”</b> provide you a variety of cardiac
          health check-up packages to cater the needs of different age groups of
          people with risk factors.
        </Typography>
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item>
            <Basic />
          </Grid>
          <Grid item>
            <Card />
          </Grid>
          <Grid item>
            <Master />
          </Grid>
          <Grid item>
            <Exclusive />
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      ></Grid>
    </div>
  );
}

export default Healthcheckup;
