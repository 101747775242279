import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

function ecg() {
  return (
    <div style={{margin : "14px"}}>
      <Container>
        <Typography variant="h5">What is Electrocardiogram (ECG)?</Typography>
        <br />
        <Typography paragraph>
          Electrocardiogram (ECG) is a most commonly performed, quick and
          painless test used to monitor electrical signals of the heart
        </Typography>
        <Typography variant="h5"> What are the uses of ECG??</Typography>
        <br />
        <Typography paragraph>
          ECG helps in identifying various heart problems such as
          <li>
            Hear attacks (Due to blockage in the blood vessels supplying the
            heart)
          </li>
          <li> Abnormal heart beats</li>
          <li> For checking the functioning of a pacemaker</li>
          <li> Detection of enlargement of heart chambers</li>
          <li>Identifying any old heart attacks had happened</li>
        </Typography>
      </Container>
    </div>
  );
}

export default ecg;
