import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import maps from "../assests/maps.png";

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: "8px",
    height: "100%",
    width: "100%",
  },
  phone: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    flexDirection: "row",
    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default function MediaControlCard() {
  const classes = useStyles();

  return (
    <>
      <Grid container  style={{ margin: "14px" }}>
        <Grid item xs={12} sm={6}>
          <Card className={classes.root}>
            <br />
            <Typography variant="h5" align="center" paragraph>
              UV CARDIAC CARE HOSPITAL
            </Typography>
            <Typography paragraph align="center">
              10-13-534, Reddy & Reddy Colony, Tirupati - 517 501, Chittoor
              District,
              <br /> Andhra Pradesh.
            </Typography>
            <div className={classes.phone}>
              <Typography paragraph align="center">
                <span>Phone:</span> 0877-2221188
              </Typography>

              {/* <Divider orientation="vertical" variant="fullWidth" /> */}
              <Divider
                orientation="vertical"
                flexItem
                style={{ margin: "0px 20px 0px 20px" }}
              />
              <Typography paragraph align="center">
                <span>Email:</span> uvcchospital@gmail.com
              </Typography>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={maps} alt="img" className={classes.img} />
        </Grid>
      </Grid>
    </>
  );
}
