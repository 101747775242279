import React from "react";
import Carousel from "react-material-ui-carousel";
import Img3 from "../assests/Banner3.jpg"
import { makeStyles } from "@material-ui/core/styles";



import {
  Card,
  CardMedia,
  Typography,
  Grid,
 
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  banner: {
    height: "370px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
        height: "180px",
    },
    borderRadius:"0px",
  },
}));

function Banner(props) {
  const classes = useStyles();
  const totalItems = props.length ? props.length : 2;
  const mediaLength = totalItems - 1;

  let items = [];

  for (let i = 0; i < mediaLength; i++) {
    const item = props.item.Items[i];

    const media = (
      <Grid item xs={12} key={item.Name}>
        <CardMedia
          className="Media"
          image={item.Image}
          style={{ height: "100%" }}
        >
          <Typography className="MediaCaption">{item.Name}</Typography>
        </CardMedia>
      </Grid>
    );

    items.push(media);
  }

  return (
    <Card raised  className={classes.banner} elevation={0}>
      <Grid
        container
        spacing={0}
        className="BannerGrid"
        style={{ height: "100%", position: "relative" }}
      >
        {items}
      </Grid>
    </Card>
  );
}

const items = [
  {
    Items: [
      {
        Image:"https://www.yashodahospital.org/wp-content/uploads/2018/09/cardiology-1200x300.jpg",
      },
    ],
  },
  {
    Items: [
      {
        Image:
         "https://hy.health.gov.il/eng/_uploads/extraimg/cardiology-wide.jpg",
      },
    ],
  },
  {
    Items: [
      {
        Image: Img3,
      },
    ],
  },
];

class BannerExample extends React.Component {
  render() {
    return (
      <div>
        <Carousel IndicatorIcon autoPlay animation="slide">
          {items.map((item, index) => {
            return (
              <Banner
                item={item}
                key={index}
                contentPosition={item.contentPosition}
              />
            );
          })}
        </Carousel>
      </div>
    );
  }
}

export default BannerExample;
