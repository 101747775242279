import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Img from "../assests/gallery.jpg";
import Img1 from "../assests/gallery1.jpg";
import Recption from "../assests/reception.jpg";
import Echo from "../assests/echo.jpg";
import tredmil from "../assests/tredmil.jpeg";
import medicalshop from "../assests/medicalshop.jpeg";
import { GridList, GridListTile } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: "15px",
    height: "100%",
    width:"100%"
  },
  media: {
    [theme.breakpoints.only("xs")]: {
      height: 300,
      maxWidth: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      height: 300,
      maxWidth: "100%",
    },
    [theme.breakpoints.only("md")]: {
      height: 300,
      maxWidth: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      height: 300,
      maxWidth: "100%",
    },

    media: {
      height: 300,
    },
  },
}));

const MyComponent = (props) => {
  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 4;
    }

    if (isWidthUp("lg", props.width)) {
      return 3;
    }

    if (isWidthUp("md", props.width)) {
      return 2;
    }

    if (isWidthUp("sm", props.width)) {
      return 2;
    }

    return 1;
  };

  

  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <GridList cellHeight={300} spacing={30} cols={getGridListCols()}>
        <GridListTile>
          <Card>
            <img src={Recption} className={classes.img} alt="img" />
          </Card>
        </GridListTile>
        <GridListTile>
          <Card>
            <img src={Echo} alt="img" className={classes.img} />
          </Card>
        </GridListTile>
        <GridListTile>
          <Card>
            <img src={tredmil} alt="img" className={classes.img} />
          </Card>
        </GridListTile>
        <GridListTile>
          <Card>
            <img src={Img} alt="img" className={classes.img} />
          </Card>
        </GridListTile>
        <GridListTile>
          <Card>
            <img src={Img1} alt="img" className={classes.img} />
          </Card>
        </GridListTile>
        <GridListTile>
          <Card>
            <img src={medicalshop} alt="img" className={classes.img} />
          </Card>
        </GridListTile>
      </GridList>
    </Container>
  );
};

export default withWidth()(MyComponent);
