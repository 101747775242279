import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Img from "../assests/ecg.jpg";
import Img1 from "../assests/echocardiogram.jpg";
import Img2 from "../assests/tmt.jpg";
import Img3 from "../assests/tmt1.jpg";
import Img4 from "../assests/holter.jpg";
import Img5 from "../assests/icu.jpg";
import Img6 from "../assests/24.png";
import Img7 from "../assests/abp.jpg";
import { Link } from "react-router-dom";
import { Box, GridList, GridListTile } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },

  imgcontainer: {
    width: 1000,
  },

  media: {
    [theme.breakpoints.only("xs")]: {
      height: 300,
      maxWidth: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      height: 300,
      maxWidth: "100%",
    },
    [theme.breakpoints.only("md")]: {
      height: 300,
      maxWidth: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      height: 300,
      maxWidth: "100%",
    },

    media: {
      height: 180,
    },
  },
  // card: {

  //   width:"10px"
  // }
}));

const MyComponent = (props) => {
  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 4;
    }

    if (isWidthUp("lg", props.width)) {
      return 4;
    }

    if (isWidthUp("md", props.width)) {
      return 3;
    }

    if (isWidthUp("sm", props.width)) {
      return 1;
    }

    return 1;
  };

  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <GridList cellHeight={480} spacing={30} cols={getGridListCols()}>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea component={Link} to="/ecg">
              <CardMedia className={classes.media} image={Img} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  ECG
                </Typography>
                <Typography variant="body1">
                  Electrocardiogram (ECG) is a most commonly performed, quick
                  and painless test used to monitor electrical signals of the
                  heart
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea component={Link} to="/echocardiogram">
              <CardMedia className={classes.media} image={Img1} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  ECHOCARDIOGRAM
                </Typography>
                <Typography variant="body1">
                  Echocardiogram is a non-invasive test which uses sound waves
                  to create images of the heart.
                  <br />
                  <br />
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea component={Link} to="/Tmt">
              <CardMedia className={classes.media} image={Img2} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  TMT
                </Typography>
                <Typography variant="body1">
                  Treadmill test is done while walking on a treadmill and is
                  useful for assessment of the effect of exercise on heart.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea component={Link} to="/cathlab">
              <CardMedia className={classes.media} image={Img3} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  CATH LAB
                </Typography>
                <Typography variant="body1">
                  cath lab is an operating room where minimally invasive
                  procedures are performed under X-ray guidance
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea component={Link} to="/Holter">
              <CardMedia className={classes.media} image={Img4} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  HOLTER MONITOR
                </Typography>
                <Typography variant="body1">
                  Holter monitor is a small wearable device used for continuous
                  monitoring of the heart rhythm.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia className={classes.media} image={Img5} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  ICCU
                </Typography>
                <Typography variant="body1">
                  ICCU is a place where people with critical, life threatening
                  cardiac problems will be taken care
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea component={Link} to="/Abp">
              <CardMedia className={classes.media} image={Img7} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  ABP
                </Typography>
                <Typography variant="body1">
                  Ambulatory Blood Pressure (ABP) monitor is a small wearable
                  device used for continuous monitoring of blood pressure
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
        <GridListTile>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia className={classes.media} image={Img6} />
              <CardContent>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  component="h2"
                >
                  <Box m={2.9}>24/7 EMERGENCY SERVICES</Box>
                </Typography>
                <Typography variant="body1"></Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </GridListTile>
      </GridList>
    </Container>
  );
};

export default withWidth()(MyComponent);
