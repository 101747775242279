import React, { useState} from "react";
import {  Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/tabs";
import logo from "../assests/logo2.png";
import logo2 from "../assests/logo1.png";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';

function ElevationScroll(props) {
  const { children} = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  shadows: Array(25).fill("none"),
  root: {
    flexGrow: 1,
  },
  iconslist: {
    color: "black",
  },

  title: {
    flexGrow: 1,
  },
  toolbarmargin: {
    ...theme.mixins.toolbar,
    marginBottom: "5.8em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em",
    },
  },
  phnum: {
    height: "10px",
    [theme.breakpoints.down("xs")]: {
      height: "13px",
    },
    [theme.breakpoints.down("md")]: {
      height: "13px",
    },
  },
  contactbox: {
    marginTop: "-61px",
    color: "black",
    position: "absolute",
    right: "150px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  
  },
  location: {
    display:"flex"
  },
  phnenumber: {
   display:"flex"
  },
  phnenumberlink: {
    textDecoration:"none",
    color: "#2c77bc",
    padding:"0 0 5px 0;"
  },
  locationlink: {
    textDecoration:"none",
    color: "#2c77bc",
    padding:"0 0 5px 0;"
  },
  toolbar: {
    display: "flex",
    backgroundColor: "white",
    padding: "5px 0px 7px 0px;",
    color: "black",
  },
  secondarytoolbar: {
    minHeight: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  // TabContainer: {
  //   display: "flex",
  //   justifyItems: "center",
  // },
  logo: {
    height: "6em",
    [theme.breakpoints.down("xs")]: {
      height: "3em",
    },
    [theme.breakpoints.down("md")]: {
      height: "4em",
    },
  },
  tab: {
    ...theme.typography.tab,
    marginLeft: "40px",
  },
  logocontainer: {
    marginLeft: "40px",
    padding: "0",
    "&:hover": {
      background: "transparent",
    },
  },
  menu: {
    backgroundColor: theme.palette.common.blue,
    color: "white",
    borderRadius: "0px",
  },
  menuItem: {
    ...theme.typography.tab,
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: "50px",
    width: "50px",
  },

  drawer: {
    backgroundColor: theme.palette.common.blue,
  },
  drawerItem: {
    ...theme.typography.tab,
    color: "white",
    opacity: 0.7,
  },
  drawerItemSelected: {
    "& .MuiListItemText-root": {
      opacity: 1,
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
  divider: {
    backgroundColor: "black",
    width: "0.5px",
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);


  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e, value) => {
    setValue(value);
  };

  


  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.TabContainer}
        indicatorColor="primary"
      >
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Tab className={classes.tab} component={Link} to="/" label="Home" />
        <br />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Tab
          className={classes.tab}
          component={Link}
          to="/AboutUS"
          label="About us"
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Tab
          className={classes.tab}
          component={Link}
          to="/HealthCheckup"
          label="Health Checkup"
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Tab
          className={classes.tab}
          component={Link}
          to="/Services"
          label="Services"
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Tab
          className={classes.tab}
          component={Link}
          to="/StayingHealthy"
          label="Staying Healthy"
        />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Tab
          className={classes.tab}
          component={Link}
          to="/ContactUS"
          label="Contact us"
        />
      </Tabs>
      <Divider orientation="vertical" flexItem className={classes.divider} />
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <img className={classes.logo} alt="logo" src={logo2} />
      <SwipeableDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOPen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarmargin} />
        <List disablePadding>
          <ListItem
            divider
            button
            component={Link}
            to="/"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              Home
            </ListItemText>
          </ListItem>
          <ListItem
            divider
            button
            component={Link}
            to="/AboutUS"
            
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              About us
            </ListItemText>
          </ListItem>
          <ListItem
            divider
            button
            component={Link}
            to="/HealthCheckup"
           
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
            Health Checkup
            </ListItemText>
          </ListItem>
          <ListItem
            divider
            button
            component={Link}
            to="/Services"
            
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
            Services
            </ListItemText>
          </ListItem>
          <ListItem
            divider
            button
            component={Link}
            to="/StayingHealthy"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              Staying Healthy
            </ListItemText>
          </ListItem>
          <ListItem
            divider
            button
            component={Link}
            to="/ContactUS"
           
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
            Contact us
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar className={classes.appbar}>
          <Toolbar disableGutters className={classes.secondarytoolbar}>
            {matches ? drawer : tabs}
          </Toolbar>
          <Hidden mdDown>
            <Toolbar className={classes.toolbar}>
              <Container style={{ padding: "0px" }}>
                <Button
                  className={classes.logocontainer}
                  component={Link}
                  to="/"
                  disableRipple
                >
                  <img className={classes.logo} alt="logo" src={logo} />
                </Button>
                <div className={classes.contactbox}>
                  <div className={classes.phnenumber}>
                    <PhoneIcon color="primary" fontSize="small"></PhoneIcon>
                    <a href="tel:+9108772221188" className={classes.phnenumberlink}>
                     +91 7207302108
                    </a>
                  </div>

                  <div className={classes.location}>
                    <LocationOnIcon color="primary" fontSize="small"></LocationOnIcon> 
                    {/* <Route exact path="/Contactus" component={Contact} /> Location &amp; Directions              */}
                    <a className={classes.locationlink}  href="/ContactUS">
                      Location &amp; Directions
                    </a>
                  </div>
                </div>
              </Container>
            </Toolbar>
          </Hidden>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarmargin} />
    </React.Fragment>
  );
}
