import Container from "@material-ui/core/Container";
import ReactPlayer from 'react-player'
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { GridList, GridListTile } from "@material-ui/core";


const MyComponent = (props) => {
  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 4;
    }

    if (isWidthUp("lg", props.width)) {
      return 2;
    }

    if (isWidthUp("md", props.width)) {
      return 2;
    }

    if (isWidthUp("sm", props.width)) {
      return 1;
    }

    return 1;
  };



  return (
    <Container maxWidth="lg">
      <GridList cellHeight={300} spacing={20} cols={getGridListCols()}>
        <GridListTile>
        <ReactPlayer  width='100%'
          height='100%' url='https://www.youtube.com/watch?v=69CQsdPC2i8&t=67s&ab_channel=ClevelandClinic' />
        </GridListTile>
        <GridListTile>
        <ReactPlayer  width='100%'
          height='100%' url='https://www.youtube.com/watch?v=cLOga_mDwvs&ab_channel=HeartFoundationNZ' />
        </GridListTile>
        <GridListTile>
        <ReactPlayer  width='100%'
          height='100%' url='https://youtu.be/3qbiglL_Afc' />
        </GridListTile>
        <GridListTile>
        <ReactPlayer  width='100%'
          height='100%' url='https://youtu.be/rdfkJfolC5k' />
        </GridListTile>   
      </GridList>
    </Container>
  );
};

export default withWidth()(MyComponent);
