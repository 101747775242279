import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import vikash from "../assests/vikash.JPG";

function aboutus() {
  return (
    <div style={{ margin: "14px" }}>
      <Typography variant="h4" paragraph>
        About UVCC Hospital
      </Typography>
      <Typography paragraph>
        UV CARDAIC CARE HOSPITAL is an exclusive cardiac care centre offering
        advanced and specialized treatment round the clock under the care of
        highly qualified cardiologists, nurses and support staff.
        <br />
        We have a dedicated interventional team providing 24/7 services to help
        patients with any sort of cardiac emergencies within the “GOLDEN HOUR”
      </Typography>
      <Typography variant="h6">OUR VISION:</Typography>
      <Typography paragraph>
        We envision to be the most trusted partner of cardiac health care and
        the pioneer in providing advanced quality cardiac care at affordable
        price.
      </Typography>
      <Typography variant="h6">OUR MISSION:</Typography>
      <Typography paragraph>
        To offer quality cardiac care services provided in a convenient and
        easily accessible manner that will set community standards and exceed
        patient’s expectations.
      </Typography>
      <Typography variant="h6">OUR DOCTORS:</Typography>
      <Grid container direction="column"  align="center">
        <Grid item>
          <img
            src={vikash}
            alt="image"
            style={{ width: "15%", height: "15%", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item>
        <Typography variant="h6"> DM CARDIOLOGY <br /></Typography>
        <Typography variant="h6"> INTERVENTIONAL CARDIOLOGIST</Typography>
         
          
        </Grid>
      </Grid>
      <br />

      <Typography paragraph>
        <b>DR Vikash VM </b> did his DM cardiology from the prestigious{" "}
        <b>SRI RAMACHANDRA MEDICAL COLLEGE</b> located in Chennai. He had an
        illustrious academic career and was awarded gold medal for his
        educational brilliance. Post DM he had worked as assistant professor in
        the cardiology department in the renowned institute SVIMS
      </Typography>
      <Typography paragraph>
        He was well trained in both non invasive and invasive cardiology. He is
        adept in performing both adult and paediatric echocardiograms, stress
        echocardiogram. He was well trained in performing complex angioplasties,
        pacemaker insertion valvuloplasties and device closures.
      </Typography>
      <Typography paragraph>
        He had publications in various national and international journals like
        JACC and IJC. He was also the author of various articles in renowned
        textbooks.
      </Typography>
      <Typography variant="h6" paragraph>
        AWARDS:
      </Typography>
      <Typography paragraph>
        <b>
          Gold medal for the best outgoing student in MD from JSS UNIVERSITY –
          MYSORE 2015
        </b>
      </Typography>
      <Typography paragraph>
        <b>
          Gold medal for the best outgoing student in DM from SRI RAMCHANDRA
          UNIVERSITY OF HIGHER EDUCATION AND RESEARCH – CHENNAI 2020
        </b>
      </Typography>
      <Typography variant="h6" paragraph>
        PUBLICATIONS:
      </Typography>
      <Typography paragraph>
        {" "}
        Vikash. VM, Chandrashekhar C, Sushma Krishnegowda, , Bhaktavatsala. H.R.
        Portal vein thrombosis following umbilical vein catheterization in
        neonates. Pediatr Rev: Int J Pediatr Res
        2015;2(4):174-176.doi:10.17511/ijpr.2015.4.026.
      </Typography>
      <Typography paragraph>
        {" "}
        Vikash V M, Kumar K J, Prasad NA, Patil S. Phenytoin induced coarse
        facies, gum hypertrophy and hirsutism in a female child. Indian J
        Paediatr Dermatol 2014;15;103-4..
      </Typography>
      <Typography paragraph>
        {" "}
        Vallivedu mano vikash, dasarath boppana, ramesh s, subash chandhar,
        thoddi Ramamurthy muralidharan, sandheep george villoth. Myocardial
        strain by speckle tracking echo as a diagnostic tool in coronary artery
        disease patients. Abstracts / Indian Heart Journal 70 (2018) S14eS23.
      </Typography>
      <Typography paragraph>
        {" "}
        Mano Vikash Vallivedu, Aditya Vikram Ruia, Muralidharan Ramamurthy,
        Nagendra Senguttuvan, Vinodkumar Balakrishnan, et al. Clinical profile
        and management of pateints with acute pulmonary thromboembolism: A large
        experience from quaternary centre in south india. JACC Vol 75, Issue 11.
      </Typography>

      <Typography paragraph>
        Vikash VM, Senguttuvan NB, Suman F, Paneerselvam T, Malepati B, Ramesh
        S, Badimela P, Ramadoss M, Iyer M, Krishnamurthy P, Vinod Kumar B,
        Balasubramaniyan JV, Sadhanandham S, Jebaraj R, Manokar P, Muralidharan
        TR, Murthy JS, Thanikachalam S, Krishnamoorthy P, Baber U, Karthikeyan
        G. Comparison of the effect of Morphine and Fentanyl in patients with
        acute coronary syndrome receiving Ticagrelor - The COMET (Comparison
        Morphine, Fentayl and Ticagrelor) randomized controlled trial. Int J
        Cardiol. 2021 May 1;330:1-6.
      </Typography>
    </div>
  );
}

export default aboutus;
