import React from "react";
import Typography from "@material-ui/core/Typography";


function ABPMonitor() {
  return (
    <div style={{margin : "14px"}}>
      <Typography variant="h5">What is Abp</Typography>
      <br />
      <Typography paragraph>
        Ambulatory Blood Pressure (ABP) monitor is a small wearable device used
        for continuous monitoring of blood pressure.
      </Typography>
      <Typography variant="h5">Why is it done?</Typography>
      <br />
      <Typography paragraph>
        <li>
          During the test, continuous monitoring of blood pressure once in every
          30 minutes to 1 hour interval is performed by the machine which can be
          analysed after 24 hours.
        </li>
        <li>
          It is performed to diagnose hypertension which may be missed on
          hospital visit and also for monitoring response to medication.
        </li>
      </Typography>
    </div>
  );
}

export default ABPMonitor;
