import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Tmt from "../assests/exercise-echocardiogram-service.png";
import Holter from "../assests/ambulatory-monitor-service.png";
import Abp from "../assests/bp-monitor-service.png";
import echo from "../assests/2d.png";
import cathlab from "../assests/cathlabicon.png";
import iccu from "../assests/iccuicon.png";
import Img from "../assests/electrocardiogram-service.png";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
   margin:"14px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    padding: "10px 0px 20px 0px"
  },

  paper: {
    border: "5px",
    padding: "3px",
    maxWidth: 400,
  },
  image: {
    width: 89,
    height: 89,
    margin: "3.5px",
  },
  img: {
    // margin: "auto",
    borderRadius: "4px",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  text: {
    backgroundColor: "#ecedee",
    margin: "3.5px",
  },
  typography: {
    color: theme.palette.common.blue,
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "15px",
    textTransform: "uppercase",
  },

  typotext: {
    padding: "5px 0px 5px 0px",
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.heading}>
          <Typography variant="h4"> Cardiology Reporting & Testing</Typography>
          <br />
          <Typography className={classes.typotext}>
            <b>
              UVCC is a testing centre. Individual cardiologists can be
              consulted in their rooms listed under their profile
            </b>
          </Typography>

          <Typography className={classes.typotext}>
            <b>
              Please contact us if you have any further queries or please read
              below for more information on Cardiac Testing.
            </b>
          </Typography>
        </div>

        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Paper className={classes.paper} square>
                <Grid container spacing={0}>
                  <Grid item>
                    <ButtonBase
                      component={Link}
                      to="/ecg"
                      className={classes.image}
                    >
                      <img className={classes.img} alt="complex" src={Img} />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs container>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      className={classes.text}
                    >
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="button"
                          className={classes.typography}
                        >
                          ECG
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper className={classes.paper} square>
                <Grid container spacing={0}>
                  <Grid item>
                    <ButtonBase
                      component={Link}
                      to="/echocardiogram"
                      className={classes.image}
                    >
                      <img
                        className={classes.img}
                        alt="complex"
                        src={echo}
                      />
                    </ButtonBase>
                  </Grid>
                  <Grid item  xs container>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      className={classes.text}
                    >
                      <Grid item xs>
                        <Typography gutterBottom className={classes.typography}>
                          Echocardiogram
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper className={classes.paper} square>
                <Grid container spacing={0}>
                  <Grid item>
                    <ButtonBase
                      className={classes.image}
                      component={Link}
                      to="/Tmt"
                    >
                      <img className={classes.img} alt="complex" src={Tmt} />
                    </ButtonBase>
                  </Grid>
                  <Grid item  xs container>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      className={classes.text}
                    >
                      <Grid item xs>
                        <Typography gutterBottom className={classes.typography}>
                          Tmt
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            

            <Grid item xs={12} md={4}>
              <Paper className={classes.paper} square>
                <Grid container spacing={0}>
                  <Grid item>
                    <ButtonBase
                      className={classes.image}
                      component={Link}
                      to="/Holter"
                    >
                      <img className={classes.img} alt="complex" src={Holter} />
                    </ButtonBase>
                  </Grid>
                  <Grid item  xs container>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      className={classes.text}
                    >
                      <Grid item xs>
                        <Typography gutterBottom className={classes.typography}>
                          Holter Monitor
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper className={classes.paper} square>
                <Grid container spacing={0}>
                  <Grid item>
                    <ButtonBase
                      className={classes.image}
                      component={Link}
                      to="/Abp"
                    >
                      <img className={classes.img} alt="complex" src={Abp} />
                    </ButtonBase>
                  </Grid>
                  <Grid item  xs container>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      className={classes.text}
                    >
                      <Grid item xs>
                        <Typography gutterBottom className={classes.typography}>
                          Ambulatory Blood Pressure Monitor (ABP Monitor)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper className={classes.paper} square>
                <Grid container spacing={0}>
                  <Grid item>
                    <ButtonBase
                      component={Link}
                      to="/cathlab"
                      className={classes.image}
                    >
                      <img className={classes.img} alt="complex" src={cathlab} />
                    </ButtonBase>
                  </Grid>
                  <Grid item  xs container>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      className={classes.text}
                    >
                      <Grid item xs>
                        <Typography gutterBottom className={classes.typography}>
                          Cath lab
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper className={classes.paper} square>
                <Grid container spacing={0}>
                  <Grid item>
                    <ButtonBase className={classes.image}>
                      <img className={classes.img} alt="complex" src={iccu} />
                    </ButtonBase>
                  </Grid>
                  <Grid item  xs container>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      className={classes.text}
                    >
                      <Grid item xs>
                        <Typography
                          gutterBottoms
                          className={classes.typography}
                        >
                          Intensive cardiac care unit
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            
          </Grid>
        </div>
      </Container>
    </div>
  );
}
