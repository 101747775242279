import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';



const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },

  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    zIndex: 1302,
    
  },
  foot: {
    backgroundColor: theme.palette.common.blue,
  },
  title: {
    ...theme.typography.footer,
  },
  links: {
    ...theme.typography.footerlinks,
  },
  socialicons:{
    color: "white",
    
  },
  socialicon:{
    color: "white",
    margin:"15px"
  }
}));


// <Route exact path="/" component={Home} />
// <Route exact path="/Aboutus" component={Aboutus} />
// <Route exact path="" component={Services} />
// <Route exact path="" component={StayingHealthy} />
// <Route exact path="" component={Contact} />
// <Route
//   exact
//   path="/Testimonoals"
//   component={() => <div>Testimonoals</div>}
// />
// <Route exact path="/Tmt" component={Tmt} />
// <Route exact path="/HealthCheckup" component={HealthCheckup} />
// <Route exact path="/Holter" component={Holter} />
// <Route exact path="/Abp" component={Abp} />
// <Route exact path="/echocardiogram" component={Echocardiogram} />
// <Route exact path="/cathlab" component={Cathlab} />
// <Route exact path="/ecg" component={Ecg} />

export default function Footer() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />

      {/* Footer */}
      <div className={classes.foot}>
        <Container className={classes.footer}>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={6} sm={2}>
              <Typography className={classes.title} gutterBottom>
                MENU
              </Typography>
              <ul>
                <li>
                  <Link href="/" className={classes.links}>
                    Home
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/Aboutus" variant="subtitle1" className={classes.links}>
                    About us
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/Services" variant="subtitle1" className={classes.links}>
                    services
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/StayingHealthy" variant="subtitle1" className={classes.links}>
                    Staying healthy
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/Contactus" variant="subtitle1" className={classes.links}>
                    Contact us
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Typography variant="h6" className={classes.title} gutterBottom>
                Services
              </Typography>
              <ul>
                <li>
                  <Link href="/ecg" variant="subtitle1" className={classes.links}>
                    ECG
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/echocardiogram" variant="subtitle1" className={classes.links}>
                    Echocardiogram
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/Tmt" variant="subtitle1" className={classes.links}>
                    TMT
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/cathlab" variant="subtitle1" className={classes.links}>
                    Cath Lab
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/Holter" variant="subtitle1" className={classes.links}>
                    Holter Monitor
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/Abp" variant="subtitle1" className={classes.links}>
                    Abp Monitor
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="#" variant="subtitle1" className={classes.links}>
                    Intensive Cardiac Care Unit
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Typography variant="h6" className={classes.title} gutterBottom>
                Follow us
              </Typography>
              <ul>
                <li>
                  <Link href="https://www.instagram.com/uvcchospital/" variant="subtitle1" className={classes.socialicons}>
                    <InstagramIcon/>
                  </Link>
                  <Link href="https://www.youtube.com/channel/UCQuno_SbiG1H0mRGMyKMDlQ" variant="subtitle1" className={classes.socialicon}>
                    <YouTubeIcon />
                  </Link>
                  <Link href="https://www.facebook.com/profile.php?id=100067846145065" variant="subtitle1" className={classes.socialicons}>
                  <FacebookIcon/>
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="h6" className={classes.title} gutterBottom>
                Contact us
              </Typography>
              <ul>
                <li>
                  <Link  variant="subtitle1">
                  <Link href="#" variant="subtitle1" className={classes.links}>
                    
                  </Link>
                  <a href="tel:+9108772221188"  className={classes.links}>
                  +91 7207302108
                    </a>
                  </Link>
                </li>
              </ul>
              <ul>
              <li>
             
              <a className={classes.links} href="mailto: uvcchospital@gmail.com"   target="_blank">uvcchospital@gmail.com</a>
                </li>
              </ul>
             
            
            </Grid>
          </Grid>
          {/* <Box mt={5}>
            <Copyright />
          </Box> */}
        </Container>
      </div>
      {/* End footer */}
    </React.Fragment>
  );
}
