import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";


function Tmt() {
  
  return (
    <div style={{margin : "14px"}}>
      <Container>
        <Typography variant="h5">What is TMT?</Typography>
        <br />
        <Typography paragraph>
          Treadmill test (TMT) or exercise stress test is done while walking on
          a treadmill and is useful for assessment of the effect of exercise on
          heart.
        </Typography>
        <Typography variant="h5">How to be ready for TMT?</Typography>
        <br />
        <Typography paragraph>
          <li>All the medication history should be given to the doctor.</li>
          <li>
            Patient should not take anything orally for at least 4 hours prior
            to the procedure.
          </li>
          <li>If the patient is male, grooming of chest hair should be done</li>
          <li>Patient should come in comfortable loose clothing</li>
        </Typography>
        <Typography variant="h5">How is TMT Performed?</Typography>
        <br />
        <Typography paragraph>
          <li>
            First step is placement of electrodes and cables on the chest wall
            which will be connected to the monitor and recording machine.
          </li>
          <li>A baseline resting ECG will be recorded.</li>
          <li>
            Treadmill is then powered on at a slow speed to achieve warm-up. By
            following Bruce protocol, staged increments in speed will be done
            once in 3 minutes with measurement of blood pressure in-between.
          </li>
          <li>
            Test will be stopped after achieving the target heart rate or
            patient develops symptoms of chest pain, giddiness or
            breathlessness..
          </li>
        </Typography>
        <Typography variant="h5"> What is positive TMT?</Typography>
        <br />
        <Typography paragraph>
          Test will be positive if patient develops significant ST depressions
          on ECG which indicates possibility of coronary artery disease (Blocks
          in blood vessels of heart). Patient with positive TMT may require
          coronary angiogram.
        </Typography>
      </Container>
    </div>
  );
}

export default Tmt;
