import React from "react";
import Typography from "@material-ui/core/Typography";
import Img1 from "../assests/Stay Healthy.jpg";
import Img2 from "../assests/Stay Healthy2.jpg";
import Img3 from "../assests/Stay Healthy3.jpg";
import Img4 from "../assests/Stay Healthy4.jpg";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  img: {
    height: "28rem",
    [theme.breakpoints.down("xs")]: {
      height: "10rem",
    },
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
}));

function Stayinghealthy() {
  const classes = useStyles();
  return (
    <div style={{ margin: "14px" }}>
      <br />
      <br />
      <br />
      <Typography variant="h4">
        WHAT ARE THE RISK FACTORS FOR CORONARY ARTERY DISEASE?
      </Typography>
      <br />
      <Grid
        container
        md={6}
        xs={12}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <img
          src={Img1}
          alt="WHAT ARE THE RISK FACTORS FOR CORONARY ARTERY DISEASE"
          className={classes.img}
        />
      </Grid>
      <br />
      <br />
      <br />
      <Typography variant="h4" className={classes.text}>
        WHAT ARE THE COMMON HEART ATTACK WARNING SIGNS?
      </Typography>
      <br />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <img
          src={Img2}
          alt="WHAT ARE THE RISK FACTORS FOR CORONARY ARTERY DISEASE"
          className={classes.img}
        />
      </Grid>
      <br />
      <br />
      <br />
      <Typography variant="h4">
        WHAT ARE THE TESTS TO DIAGNOSE CORONARY ARTERY DISEASE?
      </Typography>
      <br />
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <img
          src={Img3}
          alt="WHAT ARE THE RISK FACTORS FOR CORONARY ARTERY DISEASE"
          className={classes.img}
        />
      </Grid>
      <br />
      <br />
      <br />
      <Typography variant="h4">
        HOW TO PREVENT CORONARY ARTERY DISEASE?
      </Typography>
      <br />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <img
          src={Img4}
          alt="WHAT ARE THE RISK FACTORS FOR CORONARY ARTERY DISEASE"
          className={classes.img}
        />
      </Grid>
      <br/>
      <Typography paragraph>
        <li>
          SMOKING is BAD to HEART in so many ways, so hopefully you are not a
          smoker. If you are, please put in some effort to reduce and eliminate
          this addiction. It makes a HUGE difference to your HEART.
        </li>
        <br/>
        <li>
          High BLOOD PRESSURE is actually a really big issue and the one of the
          PRIMARY causes of a heart attack. People don’t make this as big of a
          priority, but we hope you do by following simple steps like low salt
          diet, regular exercise, proper medications and frequent checkup.
        </li>
        <br/>
        <li>
          OBESITY is becoming a pandemic in India due to life style changes. You
          need to start considering cardiac healthy diet, daily exercising and
          supplements to help lose weight.
        </li>
        <br/>
        <li>
          Controlling BLOOD SUGAR is also very important and one that most
          people don’t consider when it comes to a having a healthy heart.
          DIABETES is one of the leading risk factors for heart disease. Keep it
          in check by regular monitoring of sugars, proper diet and medications.
        </li>
        <br/>
        <li>
          And finally, the most important aspect to a healthy heart and that’s a
          healthy life style, which consists of the right kind of “diet” and
          specific type of exercises that will improve all the above-mentioned
          criteria, naturally.
        </li>
      </Typography>
    </div>
  );
}

export default Stayinghealthy;
