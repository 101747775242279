import React from "react";
import Typography from "@material-ui/core/Typography";

function holterMonitor() {
  return (
    <div style={{margin : "14px"}}>
      <Typography variant="h5">What is Holter Monitor?</Typography>
      <br />
      <Typography paragraph>
        Holter monitor is a small wearable device used for continuous monitoring
        of the heart rhythm.
      </Typography>
      <Typography variant="h5"> Why is holter monitoring done? </Typography>
      <br />
      <Typography paragraph>
        In patients with episodes of unexplained fainting or increased heart
        beat (palpitations), normal ECG may not pickup any changes as it records
        the heart rhythm for short time. Using Holter monitor, we can
        continuously record the heart beat from 1 to 7 days which will increase
        the possibility of detecting dangerous rhythm abnormalities.
      </Typography>
    </div>
  );
}

export default holterMonitor;
