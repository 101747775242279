import { createMuiTheme } from "@material-ui/core/styles";


const Blue = "#3777BC";





export default createMuiTheme({
  shadows: Array(25).fill("none"),
  palette: {
    common: {
      blue: `${Blue}`,
    },
    primary: {
      main: `${Blue}`,
    },
    secondary: {
      main: `${Blue}`,
    },
  },
  typography: {
    tab: {
      fontFamily: "Open Sans",
      fontWeight: 400,
      color: "white",
      fontSize: "13px",
    },
    footer: {
      fontFamily: "Roboto",
      textTransform: "none",
      fontWeight: 400,
      color: "white",
      fontSize: "16px",
      LineHeight: "20px",
    },
    footerlinks: {
      color: "#c5c7dc",
      fontSize: "14px",
      LineHeight: "18px",
      margin: "8px 0 8px 0px",
      
    },
    h6: {
      fontFamily: "Open Sans",
      color: "#3777BC",
    },
    h5:{
      color: "#3777BC",
      fontsize: "20px",
      fontWeight:400,
    },
    h4:{
      color: "#3777BC",
      fontFamily: "Crimson Text",
      fontSize: "35px",
      fontWeight: 400,
      LineHeight: "20px",
    },
    h7:{
   
      fontFamily: "Verdana, Arial, Helvetica, sans-serif",
      fontSize: "35px",
      fontWeight: "400",
      LineHeight: "20px",
      margin:"20px"
    },
    

  },
  overrides: {
    // Style sheet name ⚛️
    MuiCardActionArea: {
      // Name of the rule
      root: {
        // Some CSS
        
        textAlign:"center"
      },
    },
  },
});
