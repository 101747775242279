import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

function echocardiogram() {
  return (
    <div style={{margin : "14px"}}>
      <Container>
        <Typography variant="h5">What is Echocardiogram?</Typography>
        <br />
        <Typography paragraph>
          Echocardiogram is a non-invasive test which uses sound waves to create
          images of the heart.
        </Typography>
        <Typography variant="h5">
          What are the uses of Echocardiogram?
        </Typography>
        <br />
        <Typography paragraph>
          <li>Helpful in assessment of pumping capacity of the heart..</li>
          <li>Checking for leakage in heart valves</li>
          <li>Diagnosing Congenital birth defects (holes) in the heart</li>
        </Typography>
        <Typography variant="h5">
          What are the types of echocardiogram?
        </Typography>
        <br />
        <Typography paragraph>
          <li>
            Transthoracic echocardiogram which is the standard type done by
            applying transducer to the chest wall
          </li>
          <li>
            {" "}
            Trans-esophageal echocardiogram which is used for more detailed
            images, where transducer is introduced through mouth into the tube
            (esophagus) connecting mouth to stomach.
          </li>
        </Typography>
      </Container>
    </div>
  );
}

export default echocardiogram;
