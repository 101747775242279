import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function Cathlab() {
  const classes = useStyles();

  return (
    <div style={{margin : "14px"}}>
      <Container>
        <Typography variant="h5">What is Cath lab?</Typography>
        <br />
        <Typography paragraph>
          Cath lab is an operating room where minimally invasive procedures for
          treating heart and other vascular diseases are performed under X-ray
          guidance
        </Typography>
        <Typography variant="h5">
          {" "}
          What procedures can be done in Cath lab?
        </Typography>
        <br />
        <Typography paragraph>
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Coronary Angiogram
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Typography variant="h5">
                    {" "}
                    What is coronary angiogram?
                  </Typography>
                  <br />
                  <Typography paragraph>
                    Coronary angiogram is a procedure where by securing vascular
                    access in the hand or leg, a catheter is passed to the heart
                    and contrast (Dye) is injected into the coronary artery
                    (Blood vessel of heart) under X ray guidance to assess
                    blocks in the blood vessels.
                  </Typography>
                  <Typography variant="h5">
                    What are risks involved with coronary angiogram?
                  </Typography>
                  <Typography paragraph>
                    Coronary angiogram is a minimally invasive procedure with
                    very low risk of complications like bleeding, arrhythmias
                    and heart attack. Prior to procedure assessment of renal
                    function, blood clotting parameters should be done.
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Coronary angioplasty (Stenting)
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Typography variant="h5">
                    What is coronary angioplasty?
                  </Typography>
                  <br />
                  <Typography paragraph>
                    Coronary angioplasty also known as PTCA or PCI is a
                    minimally invasive procedure, used to widen the blocks
                    present in coronary arteries (blood vessels supplying the
                    heart) either by using a balloon or by placing a stent.
                  </Typography>

                  <Typography variant="h5">
                    How is coronary angioplasty done?
                  </Typography>
                  <br />
                  <Typography paragraph>
                    <li>
                      Secure access to the blood vessel either in wrist or groin
                    </li>
                    <li>
                      Pass a long tube (Guide catheter) through the blood vessel
                      to the coronary artery
                    </li>
                    <li>Pass a wire across the block in the coronary artery</li>
                    <li>
                      Pass a balloon or a stent loaded over balloon over the
                      wire across the block
                    </li>
                    <li>
                      Deploying the balloon or stent using the specialized
                      deployment devices resulting in widening or plastering of
                      the block
                    </li>
                    <br />
                    <Typography variant="h5">
                      What are the precautions to be taken post angioplasty?
                    </Typography>
                    <br />
                    <Typography paragraph>
                      <li>
                        Regular intake of tablets as prescribed by the doctor
                      </li>
                      <li>Regular health check-ups</li>
                      <li>Following healthy lifestyle</li>
                      <li>
                        Should not stop any medication without consulting
                        cardiologist
                      </li>
                    </Typography>
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Pacemaker insertion
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Typography variant="h5">What is Pacemaker?</Typography>
                  <br />
                  <Typography paragraph>
                    Pacemaker is a specialized device inserted into the body
                    through a minor surgical procedure for regulation of heart
                    beat.
                  </Typography>
                  <br />
                  <Typography variant="h5">
                    Where is Pacemaker placed?
                  </Typography>
                  <br />
                  <Typography paragraph>
                    Pacemaker insertion will be carried out in cath-lab under
                    X-Ray guidance.
                    <br />
                    Pacemaker is generally placed below the collar bone either
                    on left or right side from which a lead is connected to the
                    heart for the purpose of regulating heartbeat.
                  </Typography>
                  <br />
                  <Typography variant="h5">
                    What are the indications for pacemaker?
                  </Typography>
                  <br />
                  <Typography paragraph>
                    Pacemaker is indicated in various heart problems like
                  </Typography>
                  <li> Bradycardia (slow beating of the heart)</li>
                  <li> Irregular heart beat </li>
                  <li> Some cases of Hypertrophic cardiomyopathy</li>
                  <br />
                  <Typography variant="h5">
                    What are the complications of pacemaker procedure?
                  </Typography>
                  <br/>
                  <Typography paragraph>
                    Pacemaker insertion is a relatively safe procedure with
                    following risks
                  </Typography>
            
                  <li>Bleeding</li>
                  <li>Hematoma</li>
                  <li>Infection</li>
                  <li>Pneumothorax</li>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Typography>
      </Container>
    </div>
  );
}

export default Cathlab;
