import Home from "./screens/home";
import Header from "./components/header";
import Aboutus from "./screens/aboutus";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./components/theme";
import { BrowserRouter, Route } from "react-router-dom";
import Services from "./screens/services";
import Contact from "./screens/contactus";
import Container from "@material-ui/core/Container";
import Footer from "./components/footer";
import Tmt from "./pages/tmt";
import Holter from "./pages/holterMonitor";
import Abp from "./pages/ABPMonitor";
import Carsoule from "./components/carsoule";
// import SpeedDail from "./components/speedDail";
import ScrollToTop from "./components/scroll";
import HealthCheckup from "./screens/Healthcheckup";
import Echocardiogram from "./pages/echocardiogram";
import Cathlab from "./pages/cathlab";
import Ecg from "./pages/ecg";
import StayingHealthy from "./screens/stayinghealthy";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <ScrollToTop />

        <Carsoule />

        <Container style={{ padding: "0px" }}>
          <switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Aboutus" component={Aboutus} />
            <Route exact path="/Services" component={Services} />
            <Route exact path="/StayingHealthy" component={StayingHealthy} />
            <Route exact path="/Contactus" component={Contact} />
            <Route
              exact
              path="/Testimonoals"
              component={() => <div>Testimonoals</div>}
            />
            <Route exact path="/Tmt" component={Tmt} />
            <Route exact path="/HealthCheckup" component={HealthCheckup} />
            <Route exact path="/Holter" component={Holter} />
            <Route exact path="/Abp" component={Abp} />
            <Route exact path="/echocardiogram" component={Echocardiogram} />
            <Route exact path="/cathlab" component={Cathlab} />
            <Route exact path="/ecg" component={Ecg} />
          </switch>
        </Container>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
