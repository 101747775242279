import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Specs from "../components/ourSpecialities";
import Gallery from "../components/gallery";
import Img from "../assests/aboutus.jpg";
import Videos from "../components/videos";

const useStyles = makeStyles((theme) => ({
  Selftextone: {
   
    color: "black",
  },
  Selftexttwo: {
    fontFamily: "Sorts Mill Goudy",
    fontweight: "17px",
    opacity: "0.9",
    textTransform: "none",
    margin: "18px 0px 50px",
    color: "black",
  },
  cards: {
    margin: "10px",
  },
  image: {
    borderRadius: "15px",
    textAlign: "center",
    [theme.breakpoints.down('sm')]:{
      width:"70%"
    }
  },
  textTransform: {
    textAlign: "center",
  },
}));

const Textone = (
  <div>
    <Typography
      variant="h6"
      paragraph
      align="center"
      style={{ marginTop: 30, marginBottom: 30, color: "black" }}
    >
      We at UV CARDAIC CARE HOSPITAL are committed to provide world class state
      of the art health care facilities with finest medical skills at affordable
      prices for patients suffering with heart ailments and to make them lead a
      productive and healthy life. For us “EVERY HEART BEAT MATTERS”
    </Typography>
  </div>
);

export default function ElevateAppBar(props) {
  const theme = useTheme();
  const mediaquary = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  return (
    <div style={{ margin: "10px" }}>
    <div>
      <Typography variant="h5" align="center" className={classes.Selftextone}>
        UV CARDIAC CARE HOSPITAL is a dedicated heart care centre established at
        the centre of <br /> temple city -TIRUPATI.
      </Typography>
      <div>
        {mediaquary ? (
          Textone
        ) : (
          <Typography
            variant="h6"
            align="center"
            className={classes.Selftexttwo}
          >
            We at UV CARDAIC CARE HOSPITAL are committed to provide world class
            state of the art health care facilities with finest medical skills
            at affordable prices for patients suffering with heart ailments and
            to make them lead a productive and healthy life.
            <br /> For us <b> “EVERY HEART BEAT MATTERS”</b>
          </Typography>
        )}
        <Paper>
          <br />
          <Typography variant="h4" align="center" >
            OUR SPECIALITIES
          </Typography>
          <br />
          <Specs />
        </Paper>

        <br />
        <br />
    
        <Paper>
          <br />
          <Typography variant="h4" align="center" className={classes.cards}>
            ABOUT US
          </Typography>
          <br />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <img src={Img} alt="Italian Trulli" className={classes.image} />
          </Grid>
          <br />
          <Typography variant="body1" align="center" className={classes.text}>
            Our highly experienced doctors will ensure your well-being. We, UV
            CARDIAC CARE HOSPITAL located in Reddy&Reddy Colony, Tirupati,
            Andhra Pradesh, have the best cardiologists, to take proper care
            of our patients. We offer various health care checkups for you and
            your family. Healthcare is simplified like never before! With our
            rich knowledge and experience, be assured of quality healthcare and
            world-class medical services.
          </Typography>
        </Paper>
        <Paper>
          <br />
          <br />
          <Typography variant="h4" align="center" className={classes.cards}>
            GALLERY
          </Typography>
          <br/>
          <Gallery />
          <br />
        </Paper>
        <Paper>
          <br />
          <Typography variant="h4" align="center" className={classes.cards}>
            VIDEOS
          </Typography>
          <br />
          <br />
          <Videos/>
         
        </Paper>
      </div>
    </div>
    </div>
  );
}

//
