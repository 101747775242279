
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 330,
    },
    borderRadius: "none",
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "none"
  }
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card className={classes.root} elevation={0}>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          // onMouseOver={(event) => handleExpandClick(event)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <CardContent>
            <Typography variant="h6" style={{color:"white"}} component="p">
            Exclusive cardiac check-up 
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item style={{textDecoration:"line-through",marginRight:"15px", textTransform:"none", color: "#ecedee"}}>Rs:3000</Grid>
                <Grid item style={{ textTransform:"none"}}>Rs:1500</Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Button>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
          <div style={{ marginLeft: "40px", marginTop: "8px" }}>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b>ECG </b>
                </li>
              </ul>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b>ECHO </b>
                </li>
              </ul>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b>Stress test (TMT) </b>
                </li>
              </ul>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b>Cardiology consultation</b>
                </li>
              </ul>
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}
