import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 330,
    },
    borderRadius: "none",
    
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "none",
  },
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card className={classes.root} elevation={0}>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          // onMouseOver={(event) => handleExpandClick(event)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <CardContent>
            <Typography variant="h6" style={{ color: "white" }} component="p">
              Basic cardiac health check-up
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    textDecoration: "line-through",
                    marginRight: "15px",
                    textTransform: "none",
                    color: "#ecedee",
                  }}
                >
                  Rs:2000
                </Grid>
                <Grid item style={{ textTransform: "none" }}>
                  Rs:1000
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Button>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography disableTypography>
              <b>Complete Hemogram</b>
            </Typography>
            <div style={{ marginLeft: "40px", marginTop: "8px" }}>
              <li>Haemoglobin</li>
              <li> RBC count</li>
              <li>PCV</li>
              <li>PCV</li>
              <li>MCV</li>
              <li>MCHC</li>
              <li>Total Leucocyte count</li>
              <li>Differential leucocyte count</li>
              <li>Platelet count</li>
              <li>ESR</li>
              <li>Peripheral smear</li>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b> Kidney Function test</b>
                </li>
              </ul>
              <li> Serum creatinine</li>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b> Lipid Profile Test</b>
                </li>
              </ul>
              <li>Total Cholesterol</li>
              <li>Triglycerides</li>
              <li>HDL Cholesterol</li>
              <li>LDL Cholesterol</li>
              <li>VLDL cholesterol</li>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b> Fasting blood sugar</b>
                </li>
              </ul>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b> ECG</b>
                </li>
              </ul>
              <ul style={{ margin: "10px 0px 10px 0px" }}>
                <li>
                  <b>Cardiology consultation</b>
                </li>
              </ul>
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}
